export const SIGN_UP_AND_CONTRIBUTE = 'SIGN_UP_AND_CONTRIBUTE';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const UPDATE_LOCALE = 'UPDATE_LOCALE';
export const FETCH_USER_ACCOUNT = 'FETCH_USER_ACCOUNT';
export const UPDATE_USER_ACCOUNT = 'UPDATE_USER_ACCOUNT';
export const DELETE_USER_ACCOUNT = 'DELETE_USER_ACCOUNT';
export const FETCH_USER_CONTRIBUTIONS = 'FETCH_USER_CONTRIBUTIONS';
export const UPDATE_USER_CONTRIBUTION = 'UPDATE_USER_CONTRIBUTION';
export const CREATE_USER_CONTRIBUTION = 'CREATE_USER_CONTRIBUTION';
